import axios from '@/plugins/axios';

class RAService {
  createAccount(data) {
    this.data = data;
    return axios('v1').post('channels/ra', this.data);
  }

  replyTicket(data, id) {
    this.data = data;
    return axios('v1').post(`channels/ra/tickets/${id}/reply`, this.data);
  }

  replyTicketPrivate(data, id) {
    this.data = data;
    return axios().post(`channels/ra/tickets/${id}/reply-private`, this.data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }

  checkAccount(data) {
    this.data = data;
    return axios('v1').get('channels/ra', this.data);
  }

  getTickets(data) {
    this.data = data;
    return axios().get('channels/ra/tickets', { params: this.data }).then((response) => response.data);
  }

  getTicketsByDate(data) {
    this.data = data;
    return axios().post('channels/ra/tickets/date', this.data).then((response) => response.data);
  }

  getTicketsByCity(data) {
    this.data = data;
    return axios().post('channels/ra/tickets/city', this.data).then((response) => response.data);
  }

  getTicketsBySource(data) {
    this.data = data;
    return axios().post('channels/ra/tickets/source', this.data).then((response) => response.data);
  }

  getTicketDetail(id) {
    this.id = id;
    return axios().get(`channels/ra/tickets/${id}`, { params: this.data }).then((response) => response.data);
  }

  getReputation(data) {
    this.data = data;
    return axios().get('channels/ra/reputation', { params: this.data }).then((response) => response.data);
  }
}

export default new RAService();
