<template>
  <div class="content">
    <PageHeader title="Relatórios" />
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="row m-0 col-border-xl">
              <div class="col-md-3 col-lg-3 col-xl-3">
                <div class="card-body">
                  <div class="icon-rounded float-left m-r-20">
                    <i class="fas fa-check MuiSvgIcon-root"></i>
                  </div>
                  <h5 class="card-title m-b-5 counter" data-count="0">{{this.reputation[4].answeredComplaintsCount}}</h5>
                  <h6 class="text-muted m-t-10">
                    Total respondidos
                  </h6>
                </div>
              </div>
              <div class="col-md-3 col-lg-3 col-xl-3">
                <div class="card-body">
                  <div class="icon-rounded float-left m-r-20">
                    <i class="fas fa-times MuiSvgIcon-root"></i>
                  </div>
                  <h5 class="card-title m-b-5 counter" data-count="0">{{this.reputation[4].unansweredComplaintsCount}}</h5>
                  <h6 class="text-muted m-t-10">
                    Não respondidas
                  </h6>
                </div>
              </div>
              <div class="col-md-3 col-lg-3 col-xl-3">
                <div class="card-body">
                  <div class="icon-rounded float-left m-r-20">
                    <i class="fas fa-star MuiSvgIcon-root"></i>
                  </div>
                  <h5 class="card-title m-b-5 counter" data-count="0">{{this.reputation[4].assessedComplaints}}</h5>
                  <h6 class="text-muted m-t-10">
                    Avaliados
                  </h6>
                </div>
              </div>
              <div class="col-md-3 col-lg-3 col-xl-3">
                <div class="card-body">
                  <div class="icon-rounded float-left m-r-20">
                    <i class="fas fa-clock MuiSvgIcon-root"></i>
                  </div>
                  <h5 class="card-title m-b-5 counter" data-count="0">Tempo de resposta</h5>
                  <h6 class="text-muted m-t-10">
                    {{this.reputation[4].avgAnswerTimeString}}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body block-el" v-if="fetched">
          <div>
            <div class="sc-ccbnFN jJKZnq">
              <ul class="sc-fNHLbd kjZuvk">
                <li class="sc-jMtzgO" :class="{'dkprfy' : index == 0, 'drnbYh': index != 0}"><button type="button" class="sc-lhLRcH jMoMhH" @click="index = 0"><i class="fas fa-chart-bar"></i></button></li>
                <li class="sc-jMtzgO" :class="{'dkprfy' : index == 1, 'drnbYh': index != 1}"><button type="button" class="sc-lhLRcH jMoMhH" @click="index = 1"><i class="fas fa-chart-pie"></i></button></li>
              </ul>
            </div>
            <div class="row" v-if="index == 0">
              <div class="col-sm-12">
                <div class="sc-ccbnFN text-center">
                  <h3 style="margin-bottom: 20px;margin-top: 20px;" class="text-center">Total de reclamações nos últimos 30 dias</h3>
                  <hr>
                </div>
                <v-chart :option="optionChartBar" class="chart"/>
                <div class="row hide">
                  <div class="col-sm-12"><hr></div>
                  <div class="col-sm-6">
                    <div class="alert alert-success">
                      Índice de solução: <strong>80%</strong>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="alert alert-info">
                      Voltaria a fazer negócio: <strong>80%</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-if="index == 1">
              <div class="sc-ccbnFN jJKZnq col-sm-12">
                <h3 style="margin-bottom: 20px;margin-top: 20px;">Relatório por dispositivo</h3>
                <hr>
              </div>
              <div class="col-sm-6">
                <div style="margin-top: 10px;">
                  <v-chart class="chart" :option="optionDevice" />
                </div>
              </div>
              <div class="col-sm-6" style="padding-right:30px;">
                <br>
                <br>
                <div class="row no-padding no-margin">
                  <div class="col-sm-6 no-padding"></div>
                  <div class="col-sm-3 text-right no-padding" style="padding-right:30px;"><strong>Números</strong></div>
                </div>
                <ul class="list-group">
                  <li class="list-group-item d-flex justify-content-between align-items-center" v-for="(source) in sourceReports" :key="source.source">
                    <div class="row no-margin" style="width: 100%;">
                      <div class="col-sm-6 no-padding"><span class="badge badge-primary badge-circle to-left" :style="`background: ${source.color} !important;`">&nbsp;</span> <span class="to-left" style="margin-left: 10px;">{{source.source}}</span></div>
                      <div class="col-sm-3 text-right no-padding" style="padding-right:30px;"><strong>{{source.size}}</strong></div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="row" v-if="index == 1">
              <div class="col-sm-6" style="padding-right:30px;">
                <hr>
                <h3 style="margin-bottom: 20px;margin-top: 20px;">Reclamantes por cidade</h3>
                <div class="row no-padding no-margin">
                  <div class="col-sm-6 no-padding"></div>
                  <div class="col-sm-3 text-right no-padding" style="padding-right:30px;"><strong></strong></div>
                </div>
                <ul class="list-group">
                  <li class="list-group-item d-flex justify-content-between align-items-center" v-for="(city) in cityReport" :key="city.city">
                    <div class="row no-margin" style="width: 100%;">
                      <div class="col-sm-6 no-padding"><span class="to-left" style="margin-left: 10px;">{{city.city}}</span></div>
                      <div class="col-sm-3 text-right no-padding" style="padding-right:30px;"><strong>{{city.size}}</strong></div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="static qt-block-ui" style="padding: 120px" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/PageHeader.vue';
import RAService from '@/services/ra.service';

import { BarChart } from 'echarts/charts';
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
} from 'echarts/components';
import VChart, { THEME_KEY } from 'vue-echarts';
import { graphic } from 'echarts';
import moment from 'moment-timezone';

use([
  CanvasRenderer,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
  BarChart,
]);

export default {
  name: 'RaReportDashboard',
  components: {
    PageHeader,
    VChart,
  },
  created() {
    this.getAccount();
    this.getReputation();
  },
  data() {
    return {
      fetched: false,
      isSending: false,
      index: 0,
      form: {},
      daysReport: [],
      colors: [
        '#32a852',
        '#1F77B4',
        '#ebc84b',
        '#4588f5',
        '#9b3ae0',
        '#b52bba',
        '#ecfa55',
        '#f0ad41',
      ],
      sourceReports: [],
      cityReport: [],
      reputation: [
        {}, {}, {}, {}, {},
      ],
      account: {
        deleted: true,
      },
      optionDevice: {
        tooltip: {
          backgroundColor: '#fff',
        },
        toolbox: {
          show: false,
        },
        color: [],
        calculable: true,
        series: [
          {
            type: 'pie',
            radius: [70, 100],
            center: ['50%', '50%'],
            roseType: 'area',
            itemStyle: {
              borderRadius: 5,
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '40',
                fontWeight: 'bold',
              },
            },
            data: [],
          },
        ],
      },
      optionChartBar: {
        legend: {
          data: ['Reclamações'],
          align: 'left',
          show: false,
        },
        tooltip: {
          trigger: 'axis',
          show: true,
          backgroundColor: '#fff',
          axisPointer: {
            snap: true,
            type: 'cross',
          },
        },
        xAxis: {
          axisTick: {
            alignWithLabel: true,
          },
          axisLabel: {
            rotate: 0,
          },
          type: 'category',
          data: [],
          silent: false,
          splitLine: {
            show: false,
          },
        },
        yAxis: {
          type: 'value',
          name: '',
          axisLabel: {
            formatter: (value, index) => {
              console.log(index);
              const text = value;
              return text;
            },
          },
        },
        series: [
          {
            name: 'Reclamações',
            type: 'bar',
            barMaxWidth: 13,
            smooth: false,
            itemStyle: {
              normal: {
                barBorderRadius: 0,
                color: '#18b272',
              },
            },
            data: [],
            animationDelay: (idx) => idx * 10 + 100,
          }],
        animationEasing: 'elasticOut',
        animationDelayUpdate: (idx) => idx * 5,
      },
    };
  },
  provide: {
    [THEME_KEY]: 'light',
  },
  methods: {
    getRandColor() {
      return this.colors[Math.floor(Math.random() * this.colors.length)];
    },
    changeTab(index) {
      this.index = index;
    },
    createAccount() {
      this.isSending = true;
      RAService.createAccount(this.form).then(
        (response) => {
          console.log(response);
          this.isSending = false;
          this.$toast.show({
            title: this.$t('generic-str.success'),
            content: 'Conta ativada com sucesso!',
            type: 'success',
          });
          this.getAccount();
        },
        (error) => {
          this.content = error;
        },
      );
    },
    getAccount() {
      RAService.checkAccount().then(
        (response) => {
          this.account = response;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    getReputation() {
      RAService.getReputation().then(
        (response) => {
          console.log('Reputation ra:');
          console.log(response);
          this.reputation = response;
          this.fetched = true;
        },
        (error) => {
          this.content = error;
        },
      );
      const from = '2022-07-01';// `${moment(new Date()).format('YYYY-MM')}-01`;
      const to = moment(from, 'YYYY-MM-DD').add(30, 'days');
      RAService.getTicketsByDate({
        from: moment(from).format('YYYY-MM-DD'),
        to: moment(to).format('YYYY-MM-DD'),
      }).then(
        (response) => {
          console.log('Tickets ra:');
          const dayReportThat = this.daysReport;
          response.forEach((time) => {
            const day = String(moment(time.creation_date).format('DD'));
            if (!dayReportThat.includes(day)) {
              dayReportThat[day] = { date: day, size: 0 };
            }
          });

          response.forEach((time) => {
            const day = moment(time.creation_date).format('DD');
            dayReportThat.forEach((newTime) => {
              if (newTime.date === day) {
                newTime.size += 1;
              }
            });
          });

          dayReportThat.forEach((time) => {
            this.optionChartBar.xAxis.data.push(`Dia ${time.date}`);
            this.optionChartBar.series[0].data.push(time.size);
          });
          console.log(dayReportThat);
        },
        (error) => {
          this.content = error;
        },
      );

      RAService.getTicketsByCity({}).then(
        (response) => {
          console.log('Tickets city:');
          const cityReportThat = this.cityReport;
          response.forEach((city) => {
            const cityName = String(city.customer.city[0].name);
            cityReportThat.push({
              city: cityName,
              size: 0,
            });
          });

          const uniqueCity = [];
          const cityReportThatResult = cityReportThat.filter((element) => {
            const isDuplicate = uniqueCity.includes(element.city);

            if (!isDuplicate) {
              uniqueCity.push(element.city);

              return true;
            }

            return false;
          });

          console.log('City size:');
          console.log(cityReportThatResult.length);
          response.forEach((city) => {
            const cityName = String(city.customer.city[0].name);

            cityReportThatResult.forEach((newCity) => {
              if (newCity.city.includes(cityName)) {
                newCity.size += 1;
              }
            });
          });
          this.cityReport = cityReportThatResult;
          console.log(this.cityReport);
        },
        (error) => {
          this.content = error;
        },
      );

      RAService.getTicketsBySource({}).then(
        (response) => {
          console.log('Tickets source:');
          const sourceReportsThat = this.sourceReports;
          response.forEach((source) => {
            const sourceName = String(source.ra.source_name);
            sourceReportsThat.push({
              source: sourceName,
              size: 0,
              color: this.getRandColor(),
            });
          });

          const uniqueSource = [];
          const sourceReportThatResult = sourceReportsThat.filter((element) => {
            const isDuplicate = uniqueSource.includes(element.source);

            if (!isDuplicate) {
              uniqueSource.push(element.source);

              return true;
            }

            return false;
          });

          console.log('Source size:');
          console.log(sourceReportThatResult.length);
          response.forEach((source) => {
            const sourceName = String(source.ra.source_name);

            sourceReportThatResult.forEach((newSource) => {
              if (newSource.source.includes(sourceName)) {
                newSource.size += 1;
              }
            });
          });
          this.sourceReports = sourceReportThatResult;
          console.log(this.sourceReports);
          this.optionDevice.color = [];
          this.optionDevice.series[0].data = [];
          this.sourceReports.forEach((sourceReport) => {
            this.optionDevice.color.push(sourceReport.color);
            this.optionDevice.series[0].data.push(
              {
                value: sourceReport.size,
                name: sourceReport.source,
              },
            );
          });
        },
        (error) => {
          this.content = error;
        },
      );
    },
  },
};
</script>

<style lang="scss">
.tRZRv {
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.tRZRv .stats {
  display: flex;
  flex-direction: row;
}
.diWwYI {
  display: flex;
  -moz-box-align: center;
  align-items: center;
  width: fit-content;
}
.diWwYI img {
  width: 60px;
  height: 60px;
}
.tRZRv .stats .info {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
}
.tRZRv .stats .info .description {
  font-size: 14px;
  font-weight: bold;
}
.tRZRv .stats .info .score {
  display: flex;
  -moz-box-align: center;
  align-items: center;
}
.tRZRv .stats .info .score b {
  font-size: 24px;
  font-weight: bold;
  margin-right: 3px;
}
.eVTnLD {
  color: rgb(0, 0, 0);
}
bnPPJr {
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 8px 0px;
  padding: 8px;
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  justify-content: space-around;
}
.bnPPJr a, .bnPPJr a:hover {
  text-decoration: none;
  color: inherit;
}
.bnPPJr .short-ammount {
  padding: 5px;
}
.bnPPJr .short-ammount span {
  font-size: 12px;
  color: rgb(75, 89, 99);
}
.bnPPJr .short-ammount .stats {
  display: flex;
  flex-direction: row;
  -moz-box-align: center;
  align-items: center;
}
.bnPPJr .short-ammount .stats svg {
  fill: rgb(75, 89, 99);
  width: 18px;
  height: 18px;
}
.MuiSvgIcon-root {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  -moz-user-select: none;
  color: #617182 !important;
}
.MuiSvgIcon-root {
  font-size: 16px;
}
.bnPPJr .short-ammount .stats b {
  padding-left: 8px;
  font-size: 16px;
  font-weight: 600;
}
.bnPPJr .bar {
  height: 50px;
  width: 1px;
  background-color: rgb(225, 227, 229);
}
.bnPPJr a, .bnPPJr a:hover {
  text-decoration: none;
  color: inherit;
}
.bnPPJr .short-ammount {
  padding: 5px;
}
.bnPPJr .short-ammount span {
  font-size: 12px;
  color: rgb(75, 89, 99);
}
.bnPPJr {
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 8px 0px;
  padding: 8px;
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  justify-content: space-around;
}
.cDiwRh {
  transition: max-height 1s ease-in-out 0s;
  max-height: 25000px;
  overflow: hidden;
}
.fqzUjM {
  padding: 20px 20px 0px;
}
.fqzUjM p {
  margin: 0px 0px 3px;
}
.chyWjA {
  -moz-box-align: center;
  align-items: center;
  display: flex;
  -moz-box-pack: justify;
  justify-content: space-between;
  width: 100%;
}
.gArEkU {
  width: 100%;
  background: rgb(242, 242, 248) none repeat scroll 0% 0%;
  border-radius: 20px;
  height: 13px;
  margin: 0px 8px 0px 0px;
  overflow: hidden;
}
.jAyKtJ-progress {
  border-radius: inherit;
  height: 12px;
  background: #752de6;;
}
.chyWjA span.label {
  min-width: 50px;
}
.chyWjA span {
  font-size: 16px;
  font-style: normal;
  letter-spacing: 0em;
  text-align: right;
  font-weight: 600;
  line-height: 22px;
  color: rgb(0, 0, 0);
}
.jJKZnq {
  display: flex;
  -moz-box-pack: justify;
  justify-content: space-between;
  -moz-box-align: center;
  align-items: center;
  border-bottom: 1px solid rgb(236, 236, 239);
  padding-right: 16px;
}
.kjZuvk {
  display: flex;
  -moz-box-align: center;
  align-items: center;
  overflow-x: auto;
  margin: 0px;
}
.dkprfy {
  text-align: center;
  margin: 0px;
  min-width: 80px;
  border-bottom: 3px solid #752de6;
}
.dkprfy .sc-lhLRcH {
  color: #752de6;
}
.dkprfy .sc-lhLRcH i{
  color: #752de6;
}
.jMoMhH {
  padding: 16px 32px;
}
.jMoMhH {
  border: medium none;
  background: transparent none repeat scroll 0% 0%;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: rgb(75, 89, 99);
  cursor: pointer;
  padding: 16px 0px;
}
li {
  list-style: none;
    list-style-type: none;
  list-style-type: none;
}
.sc-jMtzgO {
  width: 120px;
  text-align: center;
}
.chart {
  height: 300px;
}
.text-center {
  text-align: center !important;
}
.sc-jMtzgO button {
  outline: none !important;
}
</style>
